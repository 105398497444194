@font-face {
  font-family: 'NanumSquareRound';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: NanumSquareRound, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

ul,
li,
ol {
  list-style: none;
}

ul {
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
}

input:focus,
textarea:focus {
  outline: none !important;
  border: 2px solid #0066ff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  word-break: keep-all;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

p {
  line-height: 1.5;
}

main {
  margin-top: 60px;
}

svg,
img {
  vertical-align: top;
}

::placeholder {
  color: #81c0ba;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #81c0ba;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #81c0ba;
}
